/* eslint-disable no-new */
/* eslint-disable no-unused-vars */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/prop-types */

import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // import styles
import { Controller } from 'react-hook-form';
import { toast } from 'react-toastify';

function TextMessage({
  control,
  name,
  isRequired,
  errors,
  className,
  classNamedark,
  placeholder,
  minimLength,
  maximLength,
  label,
}) {
  const quillRef = useRef(null);
  const containerRef = useRef(null);

  // console.log('quill erors :: ', errors?.[name]?.ref?.focus());
  let classNamess = className;

  if (localStorage.getItem('theme') === 'dark') {
    classNamess = classNamedark;
  }

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link'],
      ['clean'],
      [{ align: [] }],
    ],
  };

  const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'link',
    'align',
  ];

  useEffect(() => {
    // Function to initialize tooltips manually
    const initializeTooltips = () => {
      // Initialize tooltips for toolbar formats
      document
        .querySelectorAll('.ql-toolbar .ql-formats .ql-picker .ql-picker-label')
        .forEach((element) => {
          let classes = element.parentElement.className;

          classes = classes
            .replace('ql-color-picker', '')
            .replace('ql-color', 'font-color')
            .replace('ql-background', 'highlight-color')
            .replace('ql-icon-picker', '')
            .replace('ql-picker', '')
            .trim()
            .replace('ql-', '')
            .replace('-', ' ')
            .replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());

          new window.bootstrap.Tooltip(element, {
            title: classes,
          });
        });

      // Initialize tooltips for toolbar buttons
      document.querySelectorAll('.ql-toolbar button[class*="ql-"]').forEach((element) => {
        let classes = element.className;

        classes = classes
          .replace('ql-', '')
          .replace('-', ' ')
          .replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());

        new window.bootstrap.Tooltip(element, {
          title: classes,
        });
      });
    };

    initializeTooltips(); // Call the function once when component mounts
  }, []);

  useEffect(() => {
    const noErrorForTitle = !errors.title;
    const noErrorForAuthorCredits = !errors.authorCredits;

    if (
      containerRef.current &&
      quillRef?.current &&
      errors?.[name] &&
      noErrorForTitle &&
      noErrorForAuthorCredits
    ) {
      // Access the nested `.ql-editor` div inside the container
      const editorDiv = containerRef.current.querySelector('.ql-editor');
      editorDiv?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      quillRef.current.focus();
    }
  }, [errors?.[name]]);

  return (
    <>
      <Controller
        control={control}
        name={name}
        rules={{
          required: {
            value: isRequired,
            message: `${label} is required`,
          },
          validate: (value) => {
            const text = value.replace(/<[^>]*>?/gm, ''); // Remove HTML tags
            return text.trim().length > 0 || `${label} cannot be empty spaces`;
          },
          minLength: {
            value: minimLength ?? 50,
            message: `${label} must be minimum ${minimLength ?? 50} `,
          },
          // maxLength: {
          //   value: maximLength ?? 3000,
          //   message: `${label} must be maximum ${maximLength ?? 5000} `,
          // },
        }}
        render={({ field }) => (
          <div ref={containerRef}>
            <ReactQuill
              className={classNamess ?? className}
              // placeholder={placeholder}
              theme="snow"
              modules={modules}
              formats={formats}
              value={field.value}
              onChange={(val, delta, source, editor) => {
                const text = editor.getText();
                if (text.length <= 1) {
                  field.onChange('');
                } else {
                  field.onChange(val);
                }
              }}
              ref={quillRef}
              // style={{ height: '300px' }}
            />
          </div>
        )}
      />
      <p
        style={{
          color: 'red',
          marginTop: '5px',
          fontSize: '14px',
          // fontFamily: 'SFUIDisplayRegular',
        }}
      >
        {' '}
        {errors[name] && errors[name].message}{' '}
      </p>
    </>
  );
}

TextMessage.propTypes = {
  control: PropTypes.shape({ current: PropTypes.any }).isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  isRequired: PropTypes.bool,
  minimLength: PropTypes.element,
  maximLength: PropTypes.element,
  errors: PropTypes.objectOf(
    PropTypes.shape({
      message: PropTypes.string,
    })
  ).isRequired,
  className: PropTypes.string,
  classNamedark: PropTypes.string,
};

TextMessage.defaultProps = {
  placeholder: 'Add Content Here....',
  isRequired: false,
  classNamedark: null,
  className: 'default-editor-class',
  label: '',
  minimLength: 20,
  maximLength: 1000,
};

export default TextMessage;
