/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable no-unused-vars */

import { useState } from 'react';
import { ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import { Provider } from 'react-redux';
import store from './redux/store';
import './App.css';
import Routers from './router/index';
import 'bootstrap/dist/css/bootstrap.min.css';
import TableContextProvider from './contexts/tableContext';
import { getCookies, setCookies } from './hooks/useCookies';
import envValues from './enviornment';

function App() {
  const [showBanner, setShowBanner] = useState(true);
  const cookieBannerControl = () => {
    setShowBanner(!showBanner);
    setCookies('showCookieBanner', false);
  };
  return (
    <Provider store={store}>
      <div className="App">
        <TableContextProvider>
          <Routers />
        </TableContextProvider>
        <ToastContainer autoClose={2000} hideProgressBar={false} />
      </div>
      {/* {showBanner &&
      envValues.REACT_APP_SHOW_COOKIE_BOX &&
      getCookies('showCookieBanner') !== 'false' ? (
        <div className="cookie-banner">
          <p>
            We use cookies to enhance your experience on our website. By logging in, you agree to
            our use of cookies for essential functionality and performance. For more details, please
            see our{' '}
            <a href="/privacypolicy">
              <p>[Privacy policy]</p>
            </a>
            .
          </p>
          <button type="button" className="close" onClick={cookieBannerControl}>
            I Understand
          </button>
        </div>
      ) : null} */}
    </Provider>
  );
}
export default App;
