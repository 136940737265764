/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable global-require */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import { Button, Image } from 'react-bootstrap';
import 'react-toastify/dist/ReactToastify.css';
import { Entry } from '../../redux/entrySlice';
import { TableContext } from '../../contexts/tableContext';
import styles from './view.module.scss';
import { REACT_APP_API_FETCH_CMS } from '../../redux/apiConstants';
import { getCookies, setCookies } from '../../hooks/useCookies';
import TextMessage from '../../components/TextMessage/TextMessage';

import backImg from '../../assets/back_arrow.svg';

function PrivacyPoilcy() {
  const [submitting, setSubmitting] = useState(false);
  const { dashboardStyle, bodyStyle } = useContext(TableContext);
  // const { setDashboardHeader } = useContext(TableContext);
  // const { setDashboardHeader } = useContext(TableContext);
  // const { errormsgStyle } = useContext(TableContext);
  const [content, setContent] = useState('');
  const [error, setError] = useState(null);
  const userrole = getCookies('USERROLE');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  // const { bodyStyle } = useContext(TableContext);
  // const { dashboardStyle } = useContext(TableContext);
  const dispatch = useDispatch();
  const [details, setdetails] = useState({
    description: '',
  });
  const {
    control,
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const getpreviousData = async () => {
    const data = {
      actionUrl: `cms/privacypolicy`,
      actionMethod: 'get',
      apiData: {
        name: 'privacypolicy',
      },
    };
    dispatch(Entry(data))
      .then((resp) => {
        if (resp.payload?.code === 200) {
          setdetails(resp.payload.data?.cms);
          reset(resp.payload.data?.cms);
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        return err;
      });
  };

  useEffect(() => {
    getpreviousData();
  }, []);
  async function handleSubmitForm(data) {
    setSubmitting(true);
    setError(null);
    const apiData = {
      name: 'privacypolicy',
      description: data.description,
    };
    // const patchdata = { name: 'privacypolicy', description: JSON.stringify(data.description) };
    // data.actionUrl = `cms/privacypolicy`;
    // data.actionMethod = 'patch';
    // data.apiData = apiData;

    const res = await axios.patch(`${REACT_APP_API_FETCH_CMS}`, apiData, {});
    if (res.data.code === 200) {
      toast.success('Privacy Policy Updated Successfully');
    } else {
      toast.error('updation failed');
    }
    setSubmitting(false);
  }

  return (
    <div className={styles[bodyStyle]}>
      <div className={styles[dashboardStyle]}>
        <div className="table_top_inner_head">Privacy Policy</div>
        <form onSubmit={handleSubmit(handleSubmitForm)}>
          <div className={`${styles.form_head} form_head`}>
            <div
              className={`${styles.arrowback} backBtn`}
              type="button"
              value="Back"
              onClick={() => {
                navigate(-1);
              }}
            >
              <Image className="mr-1" src={backImg} alt="Back Arrow" />
              Back
            </div>
            <Button
              type="submit"
              className="create_saveBtn__aE7s+ btn btn-primary"
              disabled={submitting}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M12 0C5.38125 0 0 5.38125 0 12C0 18.6187 5.38125 24 12 24C18.6187 24 24 18.6187 24 12C24 5.38125 18.6187 0 12 0Z"
                  fill="#38A169"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M17.8032 7.95468C18.0938 8.24531 18.0938 8.72343 17.8032 9.01406L10.772 16.0453C10.6267 16.1906 10.4345 16.2656 10.2423 16.2656C10.0501 16.2656 9.85791 16.1906 9.7126 16.0453L6.19697 12.5297C5.90635 12.2391 5.90635 11.7609 6.19697 11.4703C6.4876 11.1797 6.96572 11.1797 7.25635 11.4703L10.2423 14.4562L16.7438 7.95468C17.0345 7.65937 17.5126 7.65937 17.8032 7.95468Z"
                  fill="white"
                />
              </svg>
              {submitting ? 'Please wait..' : 'Save'}
            </Button>
            {/* <div className={styles.action_btns}>
            <Button type="submit" className={`${styles.resetbtn} ${styles.editBtn} resetBtn`}>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M19.4537 10.4057C19.0654 10.4057 18.7506 10.7205 18.7506 11.1089V21.4217C18.7506 22.0679 18.2249 22.5936 17.5787 22.5936H2.57821C1.93201 22.5936 1.40629 22.0679 1.40629 21.4217V6.42122C1.40629 5.77503 1.93201 5.24931 2.57821 5.24931H12.891C13.2794 5.24931 13.5942 4.93449 13.5942 4.54616C13.5942 4.15784 13.2794 3.84302 12.891 3.84302H2.57821C1.15658 3.84302 0 4.9996 0 6.42122V21.4217C0 22.8433 1.15658 23.9999 2.57821 23.9999H17.5787C19.0003 23.9999 20.1569 22.8433 20.1569 21.4217V11.1089C20.1569 10.7205 19.8421 10.4057 19.4537 10.4057Z" />
                <path d="M23.5202 1.80566L22.1943 0.479757C21.5546 -0.159919 20.5138 -0.159919 19.874 0.479757L9.26716 11.0867C9.169 11.1848 9.1021 11.3099 9.07482 11.446L8.41185 14.7607C8.36577 14.9912 8.43791 15.2295 8.60418 15.3958C8.73736 15.5289 8.91675 15.6017 9.10135 15.6017C9.14725 15.6017 9.19337 15.5972 9.23922 15.5881L12.5539 14.9251C12.69 14.8979 12.815 14.8309 12.9132 14.7328L23.5202 4.1259C23.5202 4.1259 23.5202 4.1259 23.5202 4.12586C24.1599 3.48623 24.1599 2.44538 23.5202 1.80566ZM12.0694 13.5879L9.99773 14.0023L10.4121 11.9306L19.0454 3.29722L20.7027 4.95458L12.0694 13.5879ZM22.5258 3.13151L21.6971 3.96019L20.0397 2.30283L20.8684 1.47419C20.9598 1.38279 21.1085 1.38274 21.1999 1.47415L22.5257 2.80005C22.6172 2.89141 22.6172 3.04015 22.5258 3.13151Z" />
              </svg>
              Edit
            </Button>
          </div> */}
          </div>

          <div className={`${styles.inner_card} inner_card`}>
            <div className="col-md-12 col-sm-12">
              <form className={`${styles.nml_form} ${styles.nml_formDes} mb-0`}>
                <div className={`${styles.form_fieldset} mb-0`}>
                  <TextMessage
                    control={control}
                    label="Description"
                    name="description"
                    fieldName="description"
                    isRequired
                    errors={errors}
                    className={styles.single_form}
                    classNamedark={styles.inputbox1}
                    placeHolder="Description..."
                    register={register}
                    minimLength={50}
                    maximLength={5000}
                    mandatory
                  />
                </div>
              </form>
            </div>
          </div>
          <br />
        </form>
        <br />
        <ToastContainer autoClose={2000} />
      </div>{' '}
    </div>
  );
}

export default PrivacyPoilcy;
