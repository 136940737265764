// Loader.js
import React from 'react';

function Loader() {
  return (
    <div className="new_loader">
      <div className="loader_box" />
    </div>
  );
}

export default Loader;
