/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */

import React from 'react';
import PropTypes from 'prop-types';
import useToggle from '../../hooks/useToggle';

function PasswordField({
  label,
  className,
  section,
  labelstyle,
  labeldarkstyle,
  classNamedark,
  eyeiconstyle,
  fieldName,
  register,
  errors,
  placeHolder,
  isRequired,
  minimLength,
  maximLength,
  mandatory,
  hideErrors,
  defaultValue,
}) {
  let classNames = labelstyle;
  let classNamess = className;

  if (localStorage.getItem('theme') === 'dark') {
    classNames = labeldarkstyle;
    classNamess = classNamedark;
  }
  const handleKeyDown = (e) => {
    if (e.key === ' ') {
      e.preventDefault();
    }
  };
  const [type, setType] = useToggle();
  return (
    <div className="form-group">
      <label htmlFor={fieldName} className={classNames ?? labelstyle}>
        {label ?? ''}{' '}
        {mandatory || isRequired ? (
          <span style={{ color: 'red', marginLeft: '3px' }}>*</span>
        ) : (
          <span />
        )}
      </label>

      <input
        type={type ? 'text' : 'password'}
        className={classNamess ?? className}
        defaultValue={defaultValue}
        onKeyDown={handleKeyDown}
        placeholder={placeHolder ?? 'Password'}
        data-toggle="tooltip"
        data-placement="top"
        // title="Password must contain at least 1 letter and 1 number"
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...register(fieldName, {
          required: {
            value: isRequired,
            //  message: `${section === 'physiotherapist' ? 'New Password' : label} is required.`,
            message: `${
              section === 'resetPassword' && label === 'New Password'
                ? 'Password'
                : section === 'resetPassword' && label === 'Confirm Password'
                ? 'Confirm password'
                : label
            } is required.`,

            // message: `Password is required.`,
          },
          minLength: {
            value: hideErrors ? 0 : minimLength,
            message: `${section === 'physiotherapist' ? 'New Password' : label} must be minimum ${
              minimLength ?? 8
            }.`,
          },
          maxLength: {
            value: hideErrors ? 0 : maximLength,
            message: `${section === 'physiotherapist' ? 'New Password' : label} must be maximum ${
              maximLength ?? 8
            }.`,
          },
          pattern: {
            // value: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/, // At least 1 letter, 1 number, min 8 characters
            //  // value: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*?&]{8,}$/,
            //  value: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*?&^#_+={}|:;<>,.?/~`-]{8,}$/,
            value:
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&^#_+={}|:;<>,.?/~`-])[A-Za-z\d@$!%*?&^#_+={}|:;<>,.?/~`-]{8,}$/,

            message: `${
              section === 'physiotherapist' ? 'New Password' : label
            } must contain at least one uppercase letter, one lowercase letter, one number, and one special character.`,
          },
        })}
      />
      <span className={eyeiconstyle ?? 'password_show'} onClick={setType}>
        {!type ? <i className="fa fa-eye-slash" /> : <i className="fa fa-eye" />}
      </span>
      <p
        style={{
          color: 'red',
          marginTop: '5px',
          fontSize: '14px',
        }}
      >
        {' '}
        {
          // Shows if error exist
          errors[fieldName] && errors[fieldName].message
        }{' '}
      </p>
    </div>
  );
}

PasswordField.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string.isRequired,
  eyeiconstyle: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
  errors: PropTypes.oneOfType([PropTypes.object]).isRequired,
  placeHolder: PropTypes.string,
  isRequired: PropTypes.bool,
  minimLength: PropTypes.number,
  maximLength: PropTypes.number,
  labelstyle: PropTypes.string.isRequired,
  mandatory: PropTypes.bool,
  labeldarkstyle: PropTypes.string,
  classNamedark: PropTypes.string,
  hideErrors: PropTypes.bool,
  section: PropTypes.string,
  defaultValue: PropTypes.string,
};
PasswordField.defaultProps = {
  mandatory: false,
  isRequired: false,
  labeldarkstyle: null,
  classNamedark: null,
  label: '',
  placeHolder: 'Password',
  minimLength: 8,
  maximLength: 20,
  hideErrors: false,
  section: '',
  defaultValue: '',
};
export default PasswordField;
